import { IClassificationAssessmentEntity } from "../entities/classificationAssessment";

export interface IGetClassificationAssessmentService {
  getClassificationAssessment(
    classificationAssessmentId: string,
  ): Promise<IClassificationAssessmentEntity>;
}

export interface IGetClassificationAssessmentUseCase {
  getClassificationAssessment(
    classificationAssessmentId: string,
  ): Promise<IClassificationAssessmentEntity>;
}

export class GetClassificationAssessmentUseCase
  implements IGetClassificationAssessmentUseCase
{
  constructor(
    private getClassificationAssessmentService: IGetClassificationAssessmentService,
  ) {}

  getClassificationAssessment(
    classificationAssessmentId: string,
  ): Promise<IClassificationAssessmentEntity> {
    return this.getClassificationAssessmentService.getClassificationAssessment(
      classificationAssessmentId,
    );
  }
}
