import { SoulPages } from "./soulRoutes";

interface Features extends Record<SoulPages, unknown> {
  ACCOUNTS_PAYABLE: {
    exportFuspSheet: boolean;
    exportFealqSheet: boolean;
  };
}

type CompanyGroupConfig = {
  routes: SoulPages[];
  features?: Partial<Features>;
};

export const companyGroupConfig: Record<string, CompanyGroupConfig> = {
  "Consultoria e Projetos": {
    routes: [
      "DASHBOARD",
      "ACCOUNTS_PAYABLE",
      "ACCOUNTS_RECEIVABLE",
      "PAYMENT_REQUESTS",
      "CONCILIATION",
      "REPORTS_PAYMENT_ACCOUNT",
      "REPORTS_COST_CENTER",
      "TRANSACTIONS_PAYMENT_ACCOUNT",
      "TRANSACTIONS_COST_CENTER",
      "COST_CENTER",
      "CLASSIFICATION_ACCOUNTS",
      "ASSESSMENT",
      "CUSTOMER",
      "PAYMENT_ACCOUNTS",
      "COMPANY",
      "PROVIDER",
      "FEE",
      "USERS",
      "COMPANY_GROUP",
      "DEBT_IMPORT",
      "REPORTS_USP_INCOME_STATEMENT",
    ],
    features: {
      ACCOUNTS_PAYABLE: {
        exportFuspSheet: false,
        exportFealqSheet: false,
      },
    },
  },
};
