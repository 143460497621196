import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  ClassificationAssessmentEntity,
  IClassificationAssessmentEntity,
} from "../../domain/entities/classificationAssessment";
import { ISaveClassificationAssessmentService } from "../../domain/usecases/saveClassificationAssessmentUseCase";

export class SaveClassificationAssessmentService
  implements ISaveClassificationAssessmentService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async saveClassificationAssessment(
    classificationAssessmentEntity: IClassificationAssessmentEntity,
  ): Promise<IClassificationAssessmentEntity> {
    const userEntity = this.getUserLocalService.get();
    const url = `/ClassificationAssessments`;

    let response;

    if (classificationAssessmentEntity.id === "") {
      response = await this.api.post<IClassificationAssessmentEntity>(
        url,
        classificationAssessmentEntity,
        {
          headers: {
            Authorization: `Bearer ${userEntity?.token}`,
          },
        },
      );
    } else if (classificationAssessmentEntity.id !== "") {
      response = await this.api.put<IClassificationAssessmentEntity>(
        url,
        classificationAssessmentEntity,
        {
          headers: {
            Authorization: `Bearer ${userEntity?.token}`,
          },
        },
      );
    }

    return new ClassificationAssessmentEntity(response);
  }
}
