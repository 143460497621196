import { IClassificationAssessmentEntity } from "../entities/classificationAssessment";

export interface ISaveClassificationAssessmentService {
  saveClassificationAssessment(
    classificationAssessmentEntity: IClassificationAssessmentEntity,
  ): Promise<IClassificationAssessmentEntity>;
}

export interface ISaveClassificationAssessmentUseCase {
  saveClassificationAssessment(
    classificationAssessmentEntity: IClassificationAssessmentEntity,
  ): Promise<IClassificationAssessmentEntity>;
}

export class SaveClassificationAssessmentUseCase
  implements ISaveClassificationAssessmentUseCase
{
  constructor(
    private saveClassificationAssessmentService: ISaveClassificationAssessmentService,
  ) {}

  saveClassificationAssessment(
    classificationAssessmentEntity: IClassificationAssessmentEntity,
  ): Promise<IClassificationAssessmentEntity> {
    return this.saveClassificationAssessmentService.saveClassificationAssessment(
      classificationAssessmentEntity,
    );
  }
}
