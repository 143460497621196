import { ReactNode } from "react";
import {
  FaAngleDoubleDown,
  FaAngleDoubleUp,
  FaBox,
  FaBuilding,
  FaCity,
  FaColumns,
  FaFileExcel,
  FaFolder,
  FaPlus,
  FaUniversity,
  FaUsers,
} from "react-icons/fa";
import { RiSendPlaneFill } from "react-icons/ri";
import { EUserProfile } from "../../../core/domain/entities/userEntity";

export enum RouteType {
  SINGLE,
  REPORT,
  TRANSACTION,
  REGISTER,
  MANAGEMENT,
  NOT_DISPLAYED,
}

export type SoulPages =
  | "DASHBOARD"
  | "ACCOUNTS_PAYABLE"
  | "ACCOUNTS_RECEIVABLE"
  | "PAYMENT_REQUESTS"
  | "CONCILIATION"
  | "REPORTS_PAYMENT_ACCOUNT"
  | "REPORTS_COST_CENTER"
  | "REPORTS_PECEGE_PAY_SALES"
  | "REPORTS_PECEGE_PAY_FEE"
  | "TRANSACTIONS_PAYMENT_ACCOUNT"
  | "TRANSACTIONS_COST_CENTER"
  | "TRANSACTIONS_PROJECT"
  | "COST_CENTER"
  | "CLASSIFICATION_ACCOUNTS"
  | "USP"
  | "ASSESSMENT"
  | "CUSTOMER"
  | "COMPETENCY"
  | "PAYMENT_ACCOUNTS"
  | "COMPANY"
  | "PROVIDER"
  | "FEE"
  | "PROJECTS"
  | "IMPORTATION_LMS"
  | "USERS"
  | "COMPANY_GROUP"
  | "DEBT_IMPORT"
  | "REPORTS_USP_INCOME_STATEMENT";

export type SoulRoute = {
  id: string;
  title: string;
  path: string;
  routeType: RouteType;
  profile: EUserProfile[];
  icon?: ReactNode;
  dashboard?: {
    color: string;
    order: number;
    dropdown?: {
      linkPath: string;
      linkTitle: string;
      linkIcon: ReactNode;
    }[];
  };
};

export const SoulRoutes: Record<SoulPages, SoulRoute> = {
  DASHBOARD: {
    id: "nav-dashboard",
    title: "Dashboard",
    icon: <FaColumns />,
    path: "/dashboard",
    routeType: RouteType.SINGLE,
    profile: [
      EUserProfile.master,
      EUserProfile.manager,
      EUserProfile.financial,
      EUserProfile.requester,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
      EUserProfile.internal,
    ],
  },
  ACCOUNTS_PAYABLE: {
    id: "nav-accounts-payable",
    title: "Contas a Pagar",
    icon: <FaAngleDoubleUp />,
    routeType: RouteType.SINGLE,
    path: "/accountsPayable",
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
      EUserProfile.internal,
    ],
    dashboard: {
      order: 0,
      color: "#e14a12",
      dropdown: [
        {
          linkIcon: <FaPlus />,
          linkTitle: "Adicionar",
          linkPath: "/accountsPayable/new",
        },
        {
          linkIcon: <FaFolder />,
          linkTitle: "Importar",
          linkPath: "/debtImport",
        },
      ],
    },
  },
  DEBT_IMPORT: {
    id: "nav-debt-import",
    title: "Importação de Débitos",
    routeType: RouteType.NOT_DISPLAYED,
    path: "/debtImport",
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
      EUserProfile.internal,
    ],
  },
  ACCOUNTS_RECEIVABLE: {
    id: "nav-accounts-receivable",
    title: "Contas a Receber",
    icon: <FaAngleDoubleDown />,
    routeType: RouteType.SINGLE,
    path: `/accountsReceivable`,
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
      EUserProfile.internal,
    ],
    dashboard: {
      order: 1,
      color: "#2c722e",
      dropdown: [
        {
          linkIcon: <FaPlus />,
          linkTitle: "Adicionar Contas a Receber",
          linkPath: "/accountsReceivable/new",
        },
      ],
    },
  },
  PAYMENT_REQUESTS: {
    id: "nav-payment-requests",
    title: "Solicitações de Pagamento",
    icon: <RiSendPlaneFill />,
    routeType: RouteType.SINGLE,
    path: "/paymentRequests",
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.requester,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.manager,
      EUserProfile.supervisor,
    ],
    dashboard: {
      order: 2,
      color: "#b71540",
      dropdown: [
        {
          linkIcon: <FaPlus />,
          linkTitle: "Adicionar",
          linkPath: "/paymentRequests/new",
        },
        {
          linkIcon: <FaFolder />,
          linkTitle: "Importar",
          linkPath: "/paymentRequests/import",
        },
      ],
    },
  },
  CONCILIATION: {
    id: "nav-conciliation",
    title: "Conciliação de Contas",
    icon: <FaFileExcel />,
    routeType: RouteType.SINGLE,
    path: `/conciliation`,
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
    dashboard: {
      order: 10,
      color: "#1e3799",
    },
  },
  REPORTS_PAYMENT_ACCOUNT: {
    id: "nav-reports-payment-accounts",
    title: "Relatórios de Contas de Pagamento e Projetos",
    icon: <FaUniversity />,
    routeType: RouteType.REPORT,
    path: "/reports/paymentAccounts",
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
    dashboard: {
      order: 9,
      color: "#f6b93b",
    },
  },
  REPORTS_USP_INCOME_STATEMENT: {
    id: "nav-reports-usp-income-statement",
    title: "Prestação de Contas USP",
    icon: <FaUniversity />,
    routeType: RouteType.REPORT,
    path: "/reports/uspIncomeStatement",
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  REPORTS_COST_CENTER: {
    id: "nav-reports-cost-center",
    title: "Relatórios de Centros de Custo",
    path: `/reports/costCenter`,
    routeType: RouteType.REPORT,
    icon: <FaCity />,
    profile: [
      EUserProfile.master,
      EUserProfile.manager,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
    dashboard: {
      order: 8,
      color: "#e58e26",
    },
  },
  REPORTS_PECEGE_PAY_SALES: {
    id: "nav-reports-pecege-pay-sales",
    title: "Relatórios de Vendas Pecege Pay",
    routeType: RouteType.REPORT,
    path: "/reports/pecegePaySales",
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  REPORTS_PECEGE_PAY_FEE: {
    id: "nav-reports-pecege-pay-fee",
    path: "/reports/pecegePayFee",
    routeType: RouteType.REPORT,
    title: "Relatórios de Tarifa Pecege Pay",
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  TRANSACTIONS_PAYMENT_ACCOUNT: {
    id: "nav-payment-account-transaction",
    title: "Movimentações de Contas de Pagamento",
    icon: <FaUniversity />,
    routeType: RouteType.TRANSACTION,
    path: `/paymentAccountTransaction`,
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
    dashboard: {
      order: 4,
      color: "#0c2461",
    },
  },
  TRANSACTIONS_COST_CENTER: {
    id: "nav-cost-center-transaction",
    title: "Movimentações de Centros de Custo",
    icon: <FaBuilding />,
    routeType: RouteType.TRANSACTION,
    path: `/costCenterTransaction`,
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
      EUserProfile.internal,
    ],
    dashboard: {
      order: 5,
      color: "#1e3799",
      dropdown: [
        {
          linkIcon: <FaPlus />,
          linkTitle: "Adicionar",
          linkPath: "/costCenterTransaction#add",
        },
        {
          linkIcon: <FaFolder />,
          linkTitle: "Importar",
          linkPath: "/costCenterTransaction#import",
        },
      ],
    },
  },
  TRANSACTIONS_PROJECT: {
    id: "nav-project-transaction",
    title: "Movimentações de Projetos",
    icon: <FaFolder />,
    routeType: RouteType.TRANSACTION,
    path: `/projectTransaction`,
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
    dashboard: {
      order: 6,
      color: "#4a69bd",
    },
  },
  COST_CENTER: {
    id: "nav-cost-center",
    title: "Centros de Custo",
    routeType: RouteType.REGISTER,
    path: "/costcenter",
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  PAYMENT_ACCOUNTS: {
    id: "nav-payment-accounts",
    title: "Contas de Pagamento",
    routeType: RouteType.REGISTER,
    path: "/paymentAccounts",
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  COMPANY: {
    id: "nav-company",
    title: "Empresas",
    routeType: RouteType.REGISTER,
    path: `/company`,
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  FEE: {
    id: "nav-fee",
    title: "Impostos",
    routeType: RouteType.REGISTER,
    path: `/fee`,
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  PROJECTS: {
    id: "nav-projects",
    title: "Projetos",
    routeType: RouteType.REGISTER,
    path: "/projects",
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  CUSTOMER: {
    id: "nav-customer",
    title: "Clientes",
    routeType: RouteType.REGISTER,
    path: `/customer`,
    icon: <FaUsers />,
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
      EUserProfile.internal,
    ],
    dashboard: {
      order: 7,
      color: "#f900e9",
    },
  },
  PROVIDER: {
    id: "nav-provider",
    title: "Fornecedores",
    icon: <FaBox />,
    routeType: RouteType.REGISTER,
    path: `/provider`,
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.requester,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.manager,
      EUserProfile.supervisor,
      EUserProfile.internal,
    ],
    dashboard: {
      order: 3,
      color: "#85017c",
      dropdown: [
        {
          linkIcon: <FaFolder />,
          linkTitle: "Importar",
          linkPath: "/provider#import",
        },
      ],
    },
  },
  COMPETENCY: {
    id: "nav-competency",
    title: "Competências",
    routeType: RouteType.REGISTER,
    path: `/competency`,
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  USP: {
    id: "nav-usp",
    title: "Classificações USP",
    routeType: RouteType.REGISTER,
    path: `/usp`,
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  ASSESSMENT: {
    id: "nav-assessment",
    title: "Classificações de Rateio",
    routeType: RouteType.REGISTER,
    path: `/assessment`,
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  CLASSIFICATION_ACCOUNTS: {
    id: "nav-account",
    title: "Classificações Contábeis",
    routeType: RouteType.REGISTER,
    path: "/account",
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  IMPORTATION_LMS: {
    id: "nav-importation-matches-lms",
    title: "Vínculos de importação de créditos LMS",
    routeType: RouteType.REGISTER,
    path: "/importationMatches",
    profile: [
      EUserProfile.master,
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    ],
  },
  USERS: {
    id: "nav-users",
    title: "Usuários",
    routeType: RouteType.MANAGEMENT,
    path: `/users`,
    profile: [EUserProfile.master, EUserProfile.supervisor],
  },
  COMPANY_GROUP: {
    id: "nav-company-group",
    title: "Grupos de Empresa",
    routeType: RouteType.MANAGEMENT,
    path: `/companyGroup`,
    profile: [EUserProfile.master],
  },
};
