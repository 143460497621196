import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { Checkbox } from "../../../../core/presentation/components/Checkbox";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { SoulDocumentInput } from "../../../../core/presentation/components/SoulDocumentInput";
import { useClassName } from "../../../../core/presentation/hooks/useClassName";
import {
  useDebounceTime,
  useDebounceTimeAsync,
} from "../../../../core/presentation/hooks/useDebounceTime";
import { useEmailValidator } from "../../../../core/presentation/hooks/useEmailValidator";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { usePreviousValue } from "../../../../core/presentation/hooks/usePreviousValue";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import { SimpleTable } from "../../../../simpleTable/presentation/components/SimpleTable";
import { departmentDestinationProfiles } from "../../../domain/entities/departmentDestinationProfiles";
import { ILinkedCompanyGroupEntity } from "../../../domain/entities/linkedCompanyGroupEntity";
import { IUserEntity, UserEntity } from "../../../domain/entities/userEntity";
import { IUserProfileEntity } from "../../../domain/entities/userProfileEntity";
import { MakeUser } from "../../../main/makeUser";
import { useCompanyGroupGrid } from "../../hooks/useCompanyGroupGrid";
import { Container, Loading } from "./styles";

interface UserFormModalProps {
  isOpen: boolean;
  currentId: string;
  useUser: MakeUser;
  isSupervisor: boolean;
  onRequestClose: () => void;
}

export function UserFormModal({
  isOpen,
  currentId,
  useUser,
  isSupervisor,
  onRequestClose,
}: UserFormModalProps) {
  const {
    getUser,
    saveUser,
    checkEmailAlreadyExists,
    listCompanyGroups,
    listLinkedCompanyGroups,
    linkCompanyGroups,
    listUserProfiles,
    listSupervisorCompanyGroups,
  } = useUser;

  const dialog = useSoulDialog();

  const [prevEmail, setPrevEmail] = usePreviousValue<string | undefined>();
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingProfiles, setLoadingProfiles] = useState(true);
  const [userProfiles, setUserProfiles] = useState<IUserProfileEntity[]>([]);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingCompanyGroups, setLoadingCompanyGroups] = useState(true);
  const [linkedCompanyGroups, setLinkedCompanyGroups] =
    useState<ILinkedCompanyGroupEntity[]>();
  const [companyGroups, setCompanyGroups] =
    useState<IResponseEntity<ILinkedCompanyGroupEntity[]>>();
  const [isCreateMode, setIsCreateMode] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  // UGLY por enquanto estou usando o hook "reset" pra colocar os
  // valores no formulario, mas talvez não seja a melhor abordagem.
  const {
    formState: { errors, dirtyFields, isValid },
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    clearErrors,
    watch,
  } = useForm<IUserEntity>({
    mode: "onTouched",
  });

  const selectedProfile = watch("profile");

  const mountedRef = useIsMounted();

  const destinationTipActorText =
    Number(selectedProfile) === EUserProfile.requester
      ? "solicitante"
      : "gestor";

  const getLinkedCompanyGroupList = useCallback(async () => {
    setLoadingCompanyGroups(true);
    const companyGroupList = await listLinkedCompanyGroups(currentId);

    setLinkedCompanyGroups(companyGroupList.data);
    setValue("companyGroups", companyGroupList.data);
    setLoadingCompanyGroups(false);
  }, [currentId, listLinkedCompanyGroups, setValue]);

  const getUserProfilesList = useCallback(async () => {
    if (!mountedRef.current) {
      return;
    }

    setLoadingProfiles(true);

    let profiles = await listUserProfiles();

    if (isSupervisor) {
      profiles = profiles.filter(p => {
        return p.key !== EUserProfile.master;
      });
    }

    setUserProfiles(profiles);

    setLoadingProfiles(false);
  }, [isSupervisor, listUserProfiles, mountedRef]);

  const handleAfterOpen = async () => {
    const isNew = currentId === "";

    setIsLoading(true);
    setIsCreateMode(isNew);

    await getUserProfilesList();

    if (isNew) {
      reset(
        UserEntity.create({
          partnerId: process.env.REACT_APP_PARTNER_ID,
        }),
      );
    } else {
      const formValues = await getUser(currentId);
      reset(formValues);

      await getLinkedCompanyGroupList();
    }

    setIsLoading(false);
  };

  const requestClose = useCallback(() => {
    setIsLoadingButton(false);
    setLinkedCompanyGroups(undefined);
    reset();
    onRequestClose();
    setPrevEmail(undefined);
  }, [onRequestClose, reset, setPrevEmail]);

  const save = useCallback(
    async (data: IUserEntity) => {
      if (
        departmentDestinationProfiles.includes(Number(data.profile)) &&
        !data.isFinancial &&
        !data.isPersonnelDepartment
      ) {
        await dialog.fire({
          icon: "warning",
          title: "Opa!",
          text: `Para salvar o cadastro de um ${destinationTipActorText}, é preciso
            selecionar ao menos um dos destinos de suas solicitações:
            Dep. Pessoal e/ou Financeiro`,
        });

        return;
      }

      setIsLoadingButton(true);

      const msg = isCreateMode
        ? "Usuário cadastrado com sucesso."
        : "Usuário atualizado com sucesso.";

      try {
        const { id } = await saveUser(data);

        await linkCompanyGroups(id, linkedCompanyGroups ?? []);

        await dialog.fire({
          title: "Feito!",
          text: msg,
          icon: "success",
          confirmButtonText: "OK",
        });

        requestClose();
      } catch (err) {
        dialog.close();
      }

      setIsLoadingButton(false);
    },
    [
      dialog,
      isCreateMode,
      linkCompanyGroups,
      linkedCompanyGroups,
      requestClose,
      saveUser,
      destinationTipActorText,
    ],
  );

  const debounceAsync = useDebounceTimeAsync();
  const emailValidator = useEmailValidator();

  const validateEmail = useCallback(
    async (email: string) => {
      if (email.length > 0) {
        const invalidEmail = emailValidator(email);

        if (invalidEmail) {
          return "Formato de e-mail inválido";
        }

        if (email !== prevEmail?.current && dirtyFields.email) {
          setPrevEmail(email);

          await debounceAsync(700);
          setLoadingEmail(true);

          const response = await checkEmailAlreadyExists({
            id: currentId,
            email,
            partnerId: process.env.REACT_APP_PARTNER_ID as string,
          });

          setLoadingEmail(false);

          if (!response.success) {
            return "Já temos um usuário cadastrado com este e-mail.";
          }

          return true;
        }

        setPrevEmail(email);

        return errors.email ? errors.email.message : true;
      }

      return "Este campo é obrigatório.";
    },
    [
      checkEmailAlreadyExists,
      currentId,
      debounceAsync,
      dirtyFields.email,
      emailValidator,
      errors.email,
      prevEmail,
      setPrevEmail,
    ],
  );

  const validatePassword = useCallback(() => {
    const { password, passwordConfirm } = getValues();

    if (password === passwordConfirm) {
      clearErrors("password");
      clearErrors("passwordConfirm");
      return true;
    }

    return false;
  }, [clearErrors, getValues]);

  const handleOnSelectionChange = useCallback(
    ({ value }) => {
      setValue("companyGroups", value, { shouldValidate: true });
      setLinkedCompanyGroups(value);
    },
    [setValue],
  );

  const debounceTime = useDebounceTime();

  const handleSearchOnChange = useCallback(
    ({ target: { value } }) => {
      setSearch(value);

      debounceTime(() => {
        setGlobalFilter(value);
      }, 700);
    },
    [debounceTime],
  );

  const { columns } = useCompanyGroupGrid();
  const { generatePayload } = useTables();

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      setLoading(true);

      const listService = isSupervisor
        ? listSupervisorCompanyGroups
        : listCompanyGroups;

      const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
      const companyGroupList = await listService(payload);

      setCompanyGroups(companyGroupList);
      setLoading(false);
    },
    [
      columns,
      isSupervisor,
      generatePayload,
      listCompanyGroups,
      listSupervisorCompanyGroups,
    ],
  );

  const passwdClassName = useClassName(() => {
    const type = errors.password?.type;

    if (
      type === "required" ||
      type === "minLength" ||
      type === "passwordNotMatch"
    ) {
      return "isInvalid";
    }

    return "";
  }, [errors.password]);

  const passwdConfirmClassName = useClassName(() => {
    const type = errors.passwordConfirm?.type;

    if (
      type === "required" ||
      type === "minLength" ||
      type === "passwordNotMatch"
    ) {
      return "isInvalid";
    }

    return "";
  }, [errors.passwordConfirm]);

  const submitClassName = useMemo(() => {
    let className = "green-bkg";

    // FIXME aqui nao podemos confiar no formState.isValid, pois por algum motivo
    // o isValid tem um atraso para atualizar, o que pode causar falsos positivos
    // ou falsos negativos ainda que o handleSubmit trabalhe da forma correta
    // por ora estamos mantendo assim pois é um debto muito pequeno em relacao a
    // validacao do form
    if (!isValid || errors.email) {
      className = "invalid-bkg";
    }

    return `form-button ${className}`;
  }, [errors.email, isValid]);

  const showPreloader = useMemo(() => {
    if (!isCreateMode) {
      return isLoading || loadingCompanyGroups || loadingProfiles;
    }

    return isLoading;
  }, [isCreateMode, isLoading, loadingCompanyGroups, loadingProfiles]);

  // REVIEW verificar se esta é a melhor forma de registrar este campo
  register("companyGroups", {
    validate: {
      required: value => {
        return value ? value.length > 0 : false;
      },
    },
  });

  useEffect(() => {
    // Isso é necessário pois temos elementos dinamicos
    // com tooltip e o ReactTooltip precisa escanea-los
    ReactTooltip.rebuild();
  }, [selectedProfile, loading, isLoadingButton]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={requestClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      onAfterOpen={handleAfterOpen}
    >
      <Container>
        <div className="react-modal-header">
          <h4>{isCreateMode ? "Novo Usuário" : "Editar Usuário"}</h4>
          <button
            className="react-modal-close"
            id="btn-cross"
            data-testid="btn-cross"
            type="button"
            onClick={() => requestClose()}
          >
            <IoMdClose />
          </button>
        </div>

        {showPreloader ? (
          <Loading>
            <FaSpinner className="spinner" />
          </Loading>
        ) : (
          <form className="form-container row">
            <div className="col-12 react-modal-body">
              {/* name */}
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>Nome</span>
                  <input
                    {...register("name", { required: true, maxLength: 100 })}
                    id="txt-name"
                    data-testid="txt-name"
                    placeholder="Nome"
                    type="text"
                    className={
                      errors.name?.type === "required" ? "isInvalid" : ""
                    }
                  />
                  <InvalidFeedback
                    condition={errors.name?.type === "required"}
                    message="Este campo é obrigatório"
                  />
                </label>
              </div>

              {/* email */}
              <div className="form-row">
                <label className="col-6 form-control">
                  <span>
                    E-mail &nbsp;
                    {loadingEmail && <FaSpinner className="spinner" />}
                  </span>
                  <input
                    {...register("email", {
                      required: true,
                      validate: {
                        email: async emailValue => validateEmail(emailValue),
                      },
                      maxLength: 100,
                    })}
                    id="txt-email"
                    data-testid="txt-email"
                    placeholder="E-mail"
                    type="text"
                    className={errors.email ? "isInvalid" : ""}
                  />
                  <InvalidFeedback
                    condition={errors.email?.type === "required"}
                    message="Este campo é obrigatório."
                  />
                  <InvalidFeedback
                    condition={errors.email?.type === "maxLength"}
                    message="Endereço de e-mail muito longo."
                  />
                  <InvalidFeedback
                    condition={!!errors.email}
                    message={errors.email?.message ?? ""}
                  />
                </label>

                {/* documento */}
                <label className="col-6 form-control">
                  <span>
                    CPF &nbsp;
                    <small>(opcional)</small>
                  </span>
                  <SoulDocumentInput
                    {...register("document", { minLength: 14 })}
                    id="txt-document"
                    data-testid="txt-document"
                    placeholder="CPF"
                    type="text"
                    className={errors.document ? "isInvalid" : ""}
                  />
                  <InvalidFeedback
                    condition={errors.document?.type === "minLength"}
                    message="Formato de CPF inválido"
                  />
                </label>
              </div>

              {/* passwords */}
              {isCreateMode && (
                <div className="form-row">
                  {/* password */}
                  <label className="col-6 form-control">
                    <span>Senha</span>
                    <input
                      {...register("password", {
                        required: true,
                        minLength: 5,
                        validate: {
                          passwordNotMatch: () => validatePassword(),
                        },
                      })}
                      id="txt-password"
                      data-testid="txt-password"
                      placeholder="Senha"
                      type="password"
                      className={passwdClassName}
                    />
                    <InvalidFeedback
                      condition={errors.password?.type === "required"}
                      message="Este campo é obrigatório."
                    />
                    <InvalidFeedback
                      condition={errors.password?.type === "minLength"}
                      message="A senha deve possuir ao menos 5 caracteres."
                    />
                    <InvalidFeedback
                      condition={errors.password?.type === "passwordNotMatch"}
                      message="Senha e confirmação de senha não conferem."
                    />
                  </label>

                  {/* password confirm */}
                  <label className="col-6 form-control">
                    <span>Confirme a senha</span>
                    <input
                      {...register("passwordConfirm", {
                        required: true,
                        minLength: 5,
                        validate: {
                          passwordNotMatch: () => validatePassword(),
                        },
                      })}
                      id="txt-passwordConfirm"
                      data-testid="txt-passwordConfirm"
                      placeholder="Senha"
                      type="password"
                      className={passwdConfirmClassName}
                    />
                    <InvalidFeedback
                      condition={errors.passwordConfirm?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                    <InvalidFeedback
                      condition={errors.passwordConfirm?.type === "minLength"}
                      message="A senha deve possuir ao menos 5 caracteres."
                    />
                    <InvalidFeedback
                      condition={
                        errors.passwordConfirm?.type === "passwordNotMatch"
                      }
                      message="Senha e confirmação de senha não conferem."
                    />
                  </label>
                </div>
              )}

              {/* profile */}
              <div className="form-row">
                <label className="col-6 form-control">
                  <span>Perfil</span>
                  <select
                    {...register("profile", { required: true })}
                    id="sel-profile"
                    data-testid="sel-profile"
                    className={
                      errors.profile?.type === "required" ? "isInvalid" : ""
                    }
                  >
                    <option value="">Selecione um perfil de usuário</option>
                    {userProfiles.map(({ key, value }) => {
                      return (
                        <option key={key} value={Number(key)}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                  <InvalidFeedback
                    condition={errors.profile?.type === "required"}
                    message="Este campo é obrigatório"
                  />
                </label>
                {departmentDestinationProfiles.includes(
                  Number(selectedProfile),
                ) && (
                  <>
                    <label
                      className="col-3 form-control"
                      data-tip={`
                          O ${destinationTipActorText} fará <br />
                          solicitações para o Dep. <br />
                          Pessoal, como salários, <br />
                          por exemplo.
                `}
                    >
                      <span>&nbsp;</span>
                      <div className="destination-chkbox">
                        <Checkbox
                          {...register("isPersonnelDepartment")}
                          label="Dep. pessoal"
                        />
                      </div>
                    </label>
                    <label
                      className="col-3 form-control"
                      data-tip={`
                          O ${destinationTipActorText} fará <br />
                          solicitações para o <br />
                          Financeiro, como compras, <br />
                          por exemplo.
                      `}
                    >
                      <span>&nbsp;</span>
                      <div className="destination-chkbox">
                        <Checkbox
                          {...register("isFinancial")}
                          label="Financeiro"
                        />
                      </div>
                    </label>
                  </>
                )}
              </div>

              {/* company groups */}
              <div className="form-row">
                <div className="col-12 form-control">
                  <div
                    className={`col-12 custom-input ${
                      (errors.companyGroups as FieldErrors)?.type === "required"
                        ? "isInvalid"
                        : ""
                    }`}
                  >
                    <Page>
                      <header>
                        <h4>
                          Vincular Grupos de empresa (
                          {linkedCompanyGroups?.length ?? 0})
                        </h4>
                      </header>
                      <article>
                        <InputSearch
                          id="txt-search-company-grp"
                          value={search}
                          onChange={handleSearchOnChange}
                        />
                      </article>
                      <article className="no-padding">
                        <SimpleTable<ILinkedCompanyGroupEntity>
                          getList={getList}
                          columns={columns}
                          loading={loading}
                          data={companyGroups}
                          selectionMode="multiple"
                          metaKeySelection={false}
                          globalFilter={globalFilter}
                          selection={linkedCompanyGroups}
                          onSelectionChange={handleOnSelectionChange}
                        />
                      </article>
                    </Page>
                  </div>
                  <InvalidFeedback
                    condition={
                      (errors.companyGroups as FieldErrors)?.type === "required"
                    }
                    message="Nenhum grupo de empresa selecionado."
                  />
                </div>
              </div>
            </div>

            {/* modal footer */}
            <div className="col-12 react-modal-footer">
              <button
                type="button"
                className="form-button red-bkg"
                id="btn-close"
                onClick={() => requestClose()}
              >
                Fechar
              </button>
              <button
                type="submit"
                className={submitClassName}
                id="btn-save"
                disabled={isLoadingButton}
                onClick={handleSubmit(save)}
              >
                Salvar {isLoadingButton && <FaSpinner className="spinner" />}
              </button>
            </div>
          </form>
        )}
      </Container>
    </Modal>
  );
}
