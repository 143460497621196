import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { companyGroupConfig } from "../../domain/entities/companyGroupConfig";
import {
  SoulPages,
  SoulRoute,
  SoulRoutes,
} from "../../domain/entities/soulRoutes";
import { useCurrentCompanyGroup } from "./useCurrentCompanyGroup";

interface UseRouterGuardProps {
  currentRoute: string;
  currentProfile: EUserProfile;
}

export function useRouterGuard(props: UseRouterGuardProps) {
  const { currentRoute, currentProfile } = props;
  const {
    currentCompanyGroup: { name: companyGroupName },
  } = useCurrentCompanyGroup();

  const allowedRoutes = companyGroupConfig?.[companyGroupName]?.routes;

  const routesNamesAndObject = Object.entries(SoulRoutes) as [
    SoulPages,
    SoulRoute,
  ][];

  const filteredRoutesAndProfiles = routesNamesAndObject
    .filter(([routeName]) => allowedRoutes?.includes(routeName) ?? true)
    .map(([, routeObject]) => routeObject);

  const routeObject = filteredRoutesAndProfiles.find(r =>
    currentRoute.includes(r.path),
  );

  return routeObject ? routeObject.profile.includes(currentProfile) : false;
}
