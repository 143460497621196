import { StatusCodes } from "http-status-codes";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { useCallback, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AccountsPayableFormPageFactory } from "../../../../accounts/payable/form/presentation/components/AccountsPayableFormPage";
import { DebtImportPageFactory } from "../../../../accounts/payable/import/presentation/components/DebtImportPage";
import { AccountsPayablePageFactory } from "../../../../accounts/payable/index/presentation/components/AccountsPayablePage";
import { AccountsReceivablePageFactory } from "../../../../accounts/receivable/index/presentation/components/AccountsReceivablePage";
import { CreatePaymentRequestPageFactory } from "../../../../accounts/requests/form/presentation/components/CreatePaymentRequestPage";
import { EditPaymentRequestPageFactory } from "../../../../accounts/requests/form/presentation/components/EditPaymentRequestPage";
import { ReviewPaymentRequestPageFactory } from "../../../../accounts/requests/form/presentation/components/ReviewPaymentRequestPage";
import { SolicitImportPageFactory } from "../../../../accounts/requests/import/presentation/components/SolicitImportPage";
import { PaymentRequestsPageFactory } from "../../../../accounts/requests/index/presentation/components/PaymentRequestsPageFactory";
import { ClassificationAccountsPageFactory } from "../../../../classificationAccount/presentation/components/ClassificationAccountsPage";
import { ClassificationAssessmentPageFactory } from "../../../../classificationAssessment/presentation/components/ClassificationAssessmentPage";
import { ClassificationUspFactory } from "../../../../classificationUsp/presentation/components/ClassificationUspPage";
import { CompanyPageFactory } from "../../../../company/presentation/components/CompanyPage";
import { CompanyGroupPageFactory } from "../../../../companyGroup/presentation/components/CompanyGroupPage";
import { CompetencyPageFactory } from "../../../../competency/presentation/components/CompetencyPage";
import { ConciliationPageFactory } from "../../../../conciliation/presentation/components/ConciliationPage";
import { IApiError } from "../../../../core/data/services/apiService";
import { Sidebar } from "../../../../core/presentation/components/Sidebar";
import { useApiResponseErrorHandlers } from "../../../../core/presentation/hooks/useApiResponseErrorHandlers";
import { useUserLocal } from "../../../../core/presentation/hooks/useUserLocal";
import { CostCenterPageFactory } from "../../../../costCenter/presentation/components/CostCenterPage";
import { CustomerPageFactory } from "../../../../customer/presentation/components/CustomerPage";
import { DashboardPage } from "../../../../dashboard/presentation/components/DashboardPage";
import { FeePageFactory } from "../../../../fee/presentation/components/FeePage";
import { ImportationLMSPageFactory } from "../../../../importationMatches/presentation/components/ImportationLMSPage";
import { PaymentAccountsPageFactory } from "../../../../paymentAccounts/presentation/components/PaymentAccountsPage";
import { ProjectsPageFactory } from "../../../../projects/presentation/components/ProjectsPage";
import { ProviderPageFactory } from "../../../../provider/presentation/components/ProviderPage";
import { CostCenterReportPageFactory } from "../../../../reports/costCenterReport/presentation/components/CostCenterReportPageFactory";
import { PaymentAccountReportPageFactory } from "../../../../reports/paymentAccountReport/presentation/components/PaymentAccountReportPage";
import { PecegePayFeeReportPageFactory } from "../../../../reports/pecegePayFeeReport/presentation/components/PecegePayFeeReportsPage";
import { PecegePaySalesReportPageFactory } from "../../../../reports/pecegePaySalesReport/presentation/components/PecegePaySalesReportsPage";
import { CostCenterTransactionsPageFactory } from "../../../../transactions/costCenterTransactions/presentation/components/CostCenterTransactionsPage";
import { PaymentAccountTransactionsPageFactory } from "../../../../transactions/paymentAccountTransactions/presentation/components/PaymentAccountTransactionsPage";
import { ProjectTransactionsPageFactory } from "../../../../transactions/projectTransactions/presentation/components/ProjectTransactionsPage";
import { UserPageFactory } from "../../../../user/presentation/components/UserPage";
import { SoulRoutes } from "../../../domain/entities/soulRoutes";
import { MakeAdmin, makeAdmin } from "../../../main/makeAdmin";
import {
  MakeAuthValidation,
  makeAuthValidation,
} from "../../../main/makeAuthValidation";
import { CompanyGroupProvider } from "../../hooks/useCurrentCompanyGroup";
import { usePageTitle } from "../../hooks/usePageTitle";
import { GuardedRoute } from "../GuardedRoute";
import { Navbar } from "../Navbar";
import { Container } from "./styles";
import { AccountReceivableFormPageFactory } from "../../../../accounts/receivable/form/presentation/components/AccountReceivableFormPage";
import { UspIncomeStatementReportPageFactory } from "../../../../reports/uspIncomeStatementReport/presentation/components/UspIncomeStatementReportPage";

interface AdminLayoutPageProps {
  useAuthValidation: MakeAuthValidation;
  useAdmin: MakeAdmin;
}

function AdminLayoutPage({
  useAdmin,
  useAuthValidation,
}: AdminLayoutPageProps) {
  const { user } = useUserLocal();
  const { validateToken } = useAuthValidation;
  const navigate = useNavigate();
  const location = useLocation();
  const title = usePageTitle(location);

  const validateTokenRequest = useCallback(async () => {
    try {
      await validateToken();
    } catch (error) {
      const { status } = (error as IApiError).response;
      const unauthorizedStatusCode = StatusCodes.UNAUTHORIZED;

      if (status === unauthorizedStatusCode) {
        navigate("/login", { replace: true });
      }
    }
  }, [navigate, validateToken]);

  useEffect(() => {
    validateTokenRequest();
  }, [validateTokenRequest]);

  useEffect(() => {
    (window as unknown as { dataLayer: object[] }).dataLayer?.push({
      event: "optimize.activate",
    });
  }, [location]);

  if (!user?.token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <CompanyGroupProvider admin={useAdmin}>
      <Sidebar />
      <Container>
        <Navbar title={title} />
        <div className="admin-header bg-gradient-soul" />
        <div className="page-content">
          <Routes>
            <Route path="/*" element={<Navigate to="/notFound" />} />
            <Route index element={<DashboardPage />} />
            <Route
              path={SoulRoutes.DASHBOARD.path}
              element={<DashboardPage />}
            />
            <Route
              path={SoulRoutes.USP.path}
              element={
                <GuardedRoute>
                  <ClassificationUspFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.COMPETENCY.path}
              element={
                <GuardedRoute>
                  <CompetencyPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.FEE.path}
              element={
                <GuardedRoute>
                  <FeePageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.ASSESSMENT.path}
              element={
                <GuardedRoute>
                  <ClassificationAssessmentPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.USERS.path}
              element={
                <GuardedRoute>
                  <UserPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.PROVIDER.path}
              element={
                <GuardedRoute>
                  <ProviderPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.CUSTOMER.path}
              element={
                <GuardedRoute>
                  <CustomerPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.COMPANY_GROUP.path}
              element={
                <GuardedRoute>
                  <CompanyGroupPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.TRANSACTIONS_COST_CENTER.path}
              element={
                <GuardedRoute>
                  <CostCenterTransactionsPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.TRANSACTIONS_PAYMENT_ACCOUNT.path}
              element={
                <GuardedRoute>
                  <PaymentAccountTransactionsPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.TRANSACTIONS_PROJECT.path}
              element={
                <GuardedRoute>
                  <ProjectTransactionsPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.COMPANY.path}
              element={
                <GuardedRoute>
                  <CompanyPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.PROJECTS.path}
              element={
                <GuardedRoute>
                  <ProjectsPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.REPORTS_COST_CENTER.path}
              element={
                <GuardedRoute>
                  <CostCenterReportPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.CLASSIFICATION_ACCOUNTS.path}
              element={
                <GuardedRoute>
                  <ClassificationAccountsPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.PAYMENT_ACCOUNTS.path}
              element={
                <GuardedRoute>
                  <PaymentAccountsPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.COST_CENTER.path}
              element={
                <GuardedRoute>
                  <CostCenterPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.REPORTS_PAYMENT_ACCOUNT.path}
              element={
                <GuardedRoute>
                  <PaymentAccountReportPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.IMPORTATION_LMS.path}
              element={
                <GuardedRoute>
                  <ImportationLMSPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.REPORTS_PECEGE_PAY_SALES.path}
              element={
                <GuardedRoute>
                  <PecegePaySalesReportPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.CONCILIATION.path}
              element={
                <GuardedRoute>
                  <ConciliationPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route
              path={SoulRoutes.REPORTS_PECEGE_PAY_FEE.path}
              element={
                <GuardedRoute>
                  <PecegePayFeeReportPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route path={SoulRoutes.ACCOUNTS_PAYABLE.path}>
              <Route
                index
                element={
                  <GuardedRoute>
                    <AccountsPayablePageFactory api={useAdmin.api} />
                  </GuardedRoute>
                }
              />
              <Route
                path=":payableId"
                element={
                  <GuardedRoute>
                    <AccountsPayableFormPageFactory api={useAdmin.api} />
                  </GuardedRoute>
                }
              />
            </Route>
            <Route
              path={SoulRoutes.DEBT_IMPORT.path}
              element={
                <GuardedRoute>
                  <DebtImportPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
            <Route path={SoulRoutes.PAYMENT_REQUESTS.path}>
              <Route
                index
                element={
                  <GuardedRoute>
                    <PaymentRequestsPageFactory api={useAdmin.api} />
                  </GuardedRoute>
                }
              />
              <Route
                path="import"
                element={
                  <GuardedRoute>
                    <SolicitImportPageFactory api={useAdmin.api} />
                  </GuardedRoute>
                }
              />
              <Route
                path="new"
                element={<CreatePaymentRequestPageFactory api={useAdmin.api} />}
              />
              <Route path=":paymentRequestId">
                <Route
                  index
                  element={<EditPaymentRequestPageFactory api={useAdmin.api} />}
                />
                <Route
                  path="review"
                  element={
                    <ReviewPaymentRequestPageFactory api={useAdmin.api} />
                  }
                />
              </Route>
            </Route>
            <Route path={SoulRoutes.ACCOUNTS_RECEIVABLE.path}>
              <Route
                index
                element={
                  <GuardedRoute>
                    <AccountsReceivablePageFactory api={useAdmin.api} />
                  </GuardedRoute>
                }
              />
              <Route
                path=":receivableId"
                element={
                  <GuardedRoute>
                    <AccountReceivableFormPageFactory api={useAdmin.api} />
                  </GuardedRoute>
                }
              />
            </Route>
            <Route
              path={SoulRoutes.REPORTS_USP_INCOME_STATEMENT.path}
              element={
                <GuardedRoute>
                  <UspIncomeStatementReportPageFactory api={useAdmin.api} />
                </GuardedRoute>
              }
            />
          </Routes>
        </div>
      </Container>
    </CompanyGroupProvider>
  );
}

export function AdminFactory() {
  const apiResponseErrorHandlers = useApiResponseErrorHandlers();

  return (
    <AdminLayoutPage
      useAdmin={makeAdmin(apiResponseErrorHandlers)}
      useAuthValidation={makeAuthValidation()}
    />
  );
}
