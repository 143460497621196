import { isAfter, isBefore, isEqual, isValid, parse } from "date-fns";
import { InputMask } from "primereact/inputmask";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import {
  CompanyGroupEntity,
  ICompanyGroupEntity,
} from "../../../domain/entities/companyGroupEntity";
import { MakeCompanyGroup } from "../../../main/makeCompanyGroup";
import { Container, Loading } from "./styles";

interface CompanyGroupFormModalProps {
  isOpen: boolean;
  currentId: string;
  useCompanyGroup: MakeCompanyGroup;
  onRequestClose: () => void;
}

export function CompanyGroupFormModal({
  isOpen,
  currentId,
  useCompanyGroup,
  onRequestClose,
}: CompanyGroupFormModalProps) {
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const { getCompanyGroup, saveCompanyGroup } = useCompanyGroup;
  const dialog = useSoulDialog();

  const [isCreateMode, setIsCreateMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  // UGLY por enquanto estou usando o hook "reset" pra colocar os
  // valores no formulario, mas talvez não seja a melhor abordagem.
  const {
    formState: { errors, isValid: isFormValid },
    control,
    register,
    handleSubmit,
    reset,
    watch,
  } = useForm<ICompanyGroupEntity>({
    mode: "all",
  });

  useEffect(() => {
    const isNew = currentId === "";
    setIsCreateMode(isNew);

    if (isNew) {
      reset(CompanyGroupEntity.create());
    } else if (!isNew) {
      const getCurrentCompanyGroup = async () => {
        setIsLoading(true);
        const formValues = await getCompanyGroup(currentId);
        const paymentDates =
          formValues.paymentRequestBlockStartDate === null &&
          formValues.paymentRequestBlockEndDate === null
            ? {
                paymentRequestBlockStartDate: "",
                paymentRequestBlockEndDate: "",
              }
            : {};
        reset({ ...formValues, ...paymentDates });
        setIsLoading(false);
      };
      getCurrentCompanyGroup();
    }
  }, [currentCompanyGroup.id, currentId, getCompanyGroup, reset]);

  const requestClose = useCallback(() => {
    setIsLoadingButton(false);
    reset();
    onRequestClose();
  }, [onRequestClose, reset]);

  const allowedProfiles = useAllowedProfiles();
  const readonly = useMemo(() => {
    return !allowedProfiles(
      EUserProfile.financial,
      EUserProfile.financialAccounting,
      EUserProfile.financialManagement,
    );
  }, [allowedProfiles]);

  const save = useCallback(
    async (data: ICompanyGroupEntity) => {
      if (readonly) {
        return;
      }

      setIsLoadingButton(true);

      const msg = isCreateMode
        ? "Grupo de empresa cadastrado com sucesso."
        : "Grupo de empresa atualizado com sucesso.";

      try {
        await saveCompanyGroup(data);

        await dialog.fire({
          title: "Feito!",
          text: msg,
          icon: "success",
          confirmButtonText: "OK",
        });

        requestClose();
      } catch (err) {
        dialog.close();
      }

      setIsLoadingButton(false);
    },
    [dialog, isCreateMode, readonly, requestClose, saveCompanyGroup],
  );

  const paymentRequestBlockStartDateValue = watch(
    "paymentRequestBlockStartDate",
  );
  const paymentRequestBlockEndDateValue = watch("paymentRequestBlockEndDate");

  const validateStartDate = useCallback(
    (value: string) => {
      if (value === paymentRequestBlockEndDateValue) {
        return true;
      }

      if (value && paymentRequestBlockEndDateValue) {
        const initialDate = parse(value, "ddMMyyyy", new Date());
        const endDate = parse(
          paymentRequestBlockEndDateValue,
          "ddMMyyyy",
          new Date(),
        );

        if (isEqual(initialDate, endDate)) {
          return true;
        }
        return isBefore(initialDate, endDate);
      }

      return true;
    },
    [paymentRequestBlockEndDateValue],
  );

  const validateEndDate = useCallback(
    (value: string) => {
      if (value === paymentRequestBlockStartDateValue) {
        return true;
      }

      if (paymentRequestBlockStartDateValue && value) {
        const initialDate = parse(
          paymentRequestBlockStartDateValue,
          "ddMMyyyy",
          new Date(),
        );
        const endDate = parse(value, "ddMMyyyy", new Date());
        if (isEqual(initialDate, endDate)) {
          return true;
        }
        return isAfter(endDate, initialDate);
      }

      return true;
    },
    [paymentRequestBlockStartDateValue],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={requestClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <div className="react-modal-header">
          <h4>
            {isCreateMode ? "Novo Grupo de empresa" : "Editar Grupo de empresa"}
          </h4>
          <button
            className="react-modal-close"
            id="btn-cross"
            data-testid="btn-cross"
            type="button"
            onClick={() => requestClose()}
          >
            <IoMdClose />
          </button>
        </div>

        {isLoading ? (
          <Loading>
            <FaSpinner className="spinner" />
          </Loading>
        ) : (
          <form className="form-container row">
            <div className="col-12 react-modal-body">
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>Nome</span>
                  <input
                    {...register("name", { required: true })}
                    id="name"
                    data-testid="txt-name"
                    placeholder="Nome"
                    type="text"
                    className={
                      errors.name?.type === "required" ? "isInvalid" : ""
                    }
                    disabled={readonly}
                  />
                  <InvalidFeedback
                    condition={errors.name?.type === "required"}
                    message="Este campo é obrigatório"
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>Dias para pagamento</span>
                  <Controller
                    name="daysForPayment"
                    rules={{ required: true, min: 1 }}
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <input
                          id="daysForPayment"
                          data-testid="txt-days-for-payment"
                          placeholder="Dias para pagamento"
                          type="text"
                          className={error ? "isInvalid" : ""}
                          disabled={readonly}
                          onBlur={onBlur}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const { value: inputValue } = event.target;
                            const formatedValue = inputValue.replace(/\D/g, "");

                            onChange({
                              ...event,
                              target: { ...event.target, value: formatedValue },
                            });
                          }}
                          value={value}
                        />
                        <InvalidFeedback
                          condition={error?.type === "required"}
                          message="Este campo é obrigatório"
                        />
                        <InvalidFeedback
                          condition={error?.type === "min"}
                          message="O valor mínimo para este campo é 1"
                        />
                      </>
                    )}
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>
                    Bloqueio de solicitação de pagamento{" "}
                    <small>(opcional)</small>
                  </span>
                </label>
              </div>
              <div className="form-row no-margin">
                <div className="col-6 form-control">
                  <Controller
                    control={control}
                    rules={{
                      validate: {
                        required: value => {
                          if (paymentRequestBlockEndDateValue !== "") {
                            return value !== "";
                          }

                          return true;
                        },
                        validDate: value => {
                          if (value !== "") {
                            return isValid(
                              parse(value, "ddMMyyyy", new Date()),
                            );
                          }

                          return true;
                        },
                        validateStartDate,
                      },
                    }}
                    name="paymentRequestBlockStartDate"
                    render={({
                      field: { onBlur, onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <InputMask
                          onBlur={onBlur}
                          onChange={onChange}
                          className={error ? "isInvalid" : ""}
                          value={value}
                          placeholder="dd/mm/aaaa"
                          mask="99/99/9999"
                          unmask
                          data-testid="paymentRequestBlockStartDate"
                        />
                        <InvalidFeedback
                          condition={error?.type === "required"}
                          message="Este campo deve ser preenchido"
                        />
                        <InvalidFeedback
                          condition={error?.type === "validDate"}
                          message="Preencha uma data válida"
                        />
                        <InvalidFeedback
                          condition={error?.type === "validateStartDate"}
                          message="A data inicial deve ser menor que a data final"
                        />
                      </>
                    )}
                  />
                </div>
                <div className="col-6 form-control">
                  <Controller
                    control={control}
                    rules={{
                      validate: {
                        required: () => {
                          if (paymentRequestBlockStartDateValue !== "") {
                            return paymentRequestBlockEndDateValue !== "";
                          }

                          return true;
                        },
                        validDate: value => {
                          if (value !== "") {
                            return isValid(
                              parse(value, "ddMMyyyy", new Date()),
                            );
                          }

                          return true;
                        },
                        validateEndDate,
                      },
                    }}
                    name="paymentRequestBlockEndDate"
                    render={({
                      field: { onBlur, onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <InputMask
                          onBlur={onBlur}
                          onChange={onChange}
                          className={error ? "isInvalid" : ""}
                          value={value}
                          placeholder="dd/mm/aaaa"
                          mask="99/99/9999"
                          unmask
                          data-testid="paymentRequestBlockEndDate"
                        />
                        <InvalidFeedback
                          condition={error?.type === "required"}
                          message="Este campo deve ser preenchido"
                        />
                        <InvalidFeedback
                          condition={error?.type === "validDate"}
                          message="Preencha uma data válida"
                        />
                        <InvalidFeedback
                          condition={error?.type === "validateEndDate"}
                          message="A data final deve ser maior que a data inicial"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 react-modal-footer">
              <button
                type="button"
                className="form-button red-bkg"
                id="btn-close"
                onClick={() => requestClose()}
              >
                Fechar
              </button>
              <button
                type="submit"
                className={`form-button ${
                  isFormValid ? "green-bkg" : "invalid-bkg"
                }`}
                id="btn-save"
                disabled={readonly || isLoadingButton}
                onClick={handleSubmit(save)}
              >
                Salvar {isLoadingButton && <FaSpinner className="spinner" />}
              </button>
            </div>
          </form>
        )}
      </Container>
    </Modal>
  );
}
