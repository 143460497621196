import { IEntity } from "../../../../../core/domain/entities/entity";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { IAccountPayableAssessmentEntity } from "./accountPayableAssessmentEntity";
import { IAccountPayableAttachmentEntity } from "./accountPayableAttachmentEntitty";

export enum EReturnStatus {
  NotReturned = 1,
  PartiallyReturned = 2,
  FullyReturned = 3,
}

export enum EAccountPayableStatus {
  Open = 1,
  Paid = 2,
  Canceled = 3,
}

export enum EAccountPayableDocumentStatus {
  Empty = "",
  Complete = 1,
  Incomplete = 2,
}

export const accountPayableDocumentStatusLang: Record<
  keyof typeof EAccountPayableDocumentStatus,
  string
> = {
  Empty: "Selecione um status",
  Complete: "Completo",
  Incomplete: "Incompleto",
};

export enum EAccountPayablePaymentMethod {
  Empty = 0,
  Billet = 1,
  CreditCard = 2,
  Check = 5,
  DirectDebit = 6,
  Cash = 4,
  Pix = 7,
  CurrentAccountTransfer = 3,
}

export interface IAccountPayableFormEntity extends IEntity {
  duplicateAccountPayableId: string | null;
  company: ITypeaheadOption | null;
  competency: ITypeaheadOption | null;
  provider: ITypeaheadOption | null;
  documentNumber: string;
  description: string;
  value: number;
  paymentMethod: IEnum | null;
  documentStatus: IEnum | null;
  // "data emissao"
  issueDate: string | null;
  // data "pagar em"
  payUntil: string | null;
  issueAsPaid: boolean;
  // data "data baixa"
  terminationDate: string | null;
  observation: string;
  project: ITypeaheadOption | null;
  classificationUsp: ITypeaheadOption | null;
  classificationAccount: ITypeaheadOption | null;
  paymentAccount: ITypeaheadOption | null;
  attachments: IAccountPayableAttachmentEntity[];
  assessments: IAccountPayableAssessmentEntity[];
  barcode: string | null;
  isTax: boolean;
  returnStatus: EReturnStatus;
  status: EAccountPayableStatus;
  fuspPurchaseOrderId: string;
  fuspReimbursement: boolean;
  correspondingProviderName: string;
  paymentRequestId?: string | null;
}

export class AccountPayableFormEntity implements IAccountPayableFormEntity {
  duplicateAccountPayableId = null;
  company = null;
  competency = null;
  provider = null;
  documentNumber = "";
  description = "";
  value = 0;
  paymentMethod = null;
  documentStatus = null;
  issueDate = null;
  payUntil = null;
  issueAsPaid = false;
  terminationDate = null;
  observation = "";
  project = null;
  classificationUsp = null;
  classificationAccount = null;
  paymentAccount = null;
  attachments = [];
  assessments = [];
  barcode = null;
  id = "";
  active = true;
  isTax = false;
  returnStatus = EReturnStatus.NotReturned;
  status = EAccountPayableStatus.Open;
  fuspPurchaseOrderId = "";
  fuspReimbursement = false;
  correspondingProviderName = "";
  paymentRequestId = "";

  constructor(init?: Partial<IAccountPayableFormEntity>) {
    Object.assign(this, init);
  }

  create(init?: Partial<IAccountPayableFormEntity>) {
    return new AccountPayableFormEntity(init);
  }
}
