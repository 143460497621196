import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  ClassificationAssessmentEntity,
  IClassificationAssessmentEntity,
} from "../../domain/entities/classificationAssessment";
import { IGetClassificationAssessmentService } from "../../domain/usecases/getClassificationAssessmentUseCase";

export class GetClassificationAssessmentService
  implements IGetClassificationAssessmentService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getClassificationAssessment(
    classificationAssessmentId: string,
  ): Promise<IClassificationAssessmentEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/ClassificationAssessments/${classificationAssessmentId}`;

    const response = await this.api.get<IClassificationAssessmentEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const clsAssessmentEntity = new ClassificationAssessmentEntity(response);

    return clsAssessmentEntity;
  }
}
